@mixin ltr {
  @if $enable-ltr {
    html:not([dir="rtl"]) & {
      @content;
    }
  }
}

@mixin rtl {
  @if $enable-rtl {
    *[dir="rtl"] & {
      @content;
    }
  }
}

@function reflect($element) {
  $string: #{$element};
  @if str-index($string, "left") {
    @return str-replace($string, "left", "right");
  }
  @if str-index($string, "right") {
    @return str-replace($string, "right", "left");
  }

  @return unquote($string);
}

@mixin ltr-rtl($property, $value, $property-rtl: null, $value-rtl: null, $important: null) {
  $property-reflected: reflect($property);
  $value-reflected: reflect($value);

  @if $enable-ltr and $enable-rtl {
    @include ltr() {
      #{$property}: $value $important;
    }
    @include rtl() {
      @if $value-rtl {
        #{$property-reflected}: $value-rtl $important;
      }
      @else {
        #{$property-reflected}: $value-reflected $important;
      }
    }
  }
  @else {
    @if $enable-rtl {
      @if $value-rtl {
        #{$property-reflected}: $value-rtl $important;
      }
      @else {
        #{$property-reflected}: $value-reflected $important;
      }
    }
    @else {
      #{$property}: $value $important;
    }
  }
}

@mixin ltr-rtl-value-only($property, $value, $value-rtl: null, $important: null) {
  $value-reflected: reflect($value);

  @if $enable-ltr and $enable-rtl {
    @include ltr() {
      #{$property}: $value $important;
    }
    @include rtl() {
      @if $value-rtl {
        #{$property}: $value-rtl $important;
      } @else {
        #{$property}: $value-reflected $important;
      }
    }
  }
  @else {
    @if $enable-rtl {
      @if $value-rtl {
        #{$property}: $value-rtl $important;
      } @else {
        #{$property}: $value-reflected $important;
      }
    } @else {
      #{$property}: $value $important;
    }
  }
}
